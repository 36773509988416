<template>
  <div class="box">
    <div class="headline">公司设置</div>
    <div class="content">
      <p>进入公司设置界面，【系统设置】→【公司与部门设置】，如下图</p>
      <img src="../../assets/specificationImgs/img11.png" alt width="100%" />
      <p>左侧树形图直观显示了集团公司的从属关系，单击《公司名称》，中间则是该公司下的部门信息，右侧显示这个公司的详细资料。</p>
      <img src="../../assets/specificationImgs/img12.png" alt width="100%" />
      <p>
        1）新增：点击公司设置下方“+”号，选择《上层公司》，按需填公司信息，点击“确定”。 <br />
        2）修改：从左侧选中一家公司，点击“修改”，按需修改公司信息，点击“确定”。 <br />
        3）删除：从左侧选中一家公司，点击“删除”。
      </p>
      <p>
        操作技巧：<br />
        1）“新增”或“修改”后，不需要保存，点击“取消”。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
